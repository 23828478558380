const eventCollect = ({ sku, action }) => {
  dataLayer.push({
    event: 'uaEvent',
    eventCategory: 'Product Detail Page_' + sku,
    eventAction: action, //open或者close
    eventLabel: '3D',
  })
  window.dataLayer.push({ event_parameters: null })
  window.dataLayer.push({
    event: 'ga4Event',
    event_name: 'small_carousel_switch',
    event_parameters: {
      page_group: 'Product Detail Page_' + sku,
      action: action, //open或者close
      info: '3D',
    },
  })
}

export function openEventCollect({ sku }) {
  eventCollect({ sku, action: 'open' })
}

export function closeEventCollect({ sku }) {
  eventCollect({ sku, action: 'close' })
}
