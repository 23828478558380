import { memo, useEffect, useState, useRef } from 'react'
import cn from 'classnames'
import { ArrowDown } from '@components/icons/next'
import styles from './LearnMore.module.css'
import { pageGTMEvent } from '@lib/utils/thirdparty'

const LearnMore = ({
  className,
  children,
  settings,
  product,
  gtmCategory,
  gtmAction,
  gtmLabel,
  ga4Event,
  minHeight = 100,
  hideLearnMore = false,
  defaultFold = true,
  s: gs = {},
  cns = {},
}) => {
  const s = { ...styles, ...gs }
  const boxMinHeight = minHeight
  const [learnMore, setLearnMore] = useState({
    fold: defaultFold,
    height: boxMinHeight,
  })
  const descDom = useRef()

  useEffect(() => {
    // 高度变化时需要刷新页面的gsap动画
    window?.ScrollTrigger &&
      setTimeout(function () {
        window.ScrollTrigger?.refresh()
      }, 200)
  }, [learnMore])

  useEffect(() => {
    if (!hideLearnMore) {
      const resizeObserver = new ResizeObserver(() => {
        const offsetHeight = descDom?.current?.getBoundingClientRect()?.height
        const active = offsetHeight > boxMinHeight
        setLearnMore({
          fold: active,
          height: active ? boxMinHeight : offsetHeight,
        })
      })
      descDom && descDom.current && resizeObserver.observe(descDom.current)
      return () => {
        descDom && descDom.current && resizeObserver.unobserve(descDom.current)
      }
    }
  }, [minHeight])
  const styleHeight = hideLearnMore ? 'auto' : `${learnMore.height}px`
  return (
    <>
      <div className={cn(s.learnMoreBox, className)}>
        <div
          className={cn(s.contentBox, {
            [s.contentLearnMore]:
              learnMore.fold &&
              learnMore.height >= boxMinHeight &&
              !hideLearnMore,
          })}
          style={{ height: `${styleHeight}` }}
        >
          <div ref={descDom} className="learn-more-container overflow-hidden">
            {children}
          </div>
        </div>
        {learnMore.height >= boxMinHeight && !hideLearnMore && (
          <button
            className={cn(s.learnMore, cns?.learnMore)}
            onClick={() => {
              pageGTMEvent({
                event: 'uaEvent',
                eventCategory: gtmCategory || `${product.handle}_listing`,
                eventAction: gtmAction || 'learn_more',
                eventLabel: gtmLabel || '',
              })
              if (ga4Event) {
                pageGTMEvent({ event_parameters: null })
                const event_parameters = ga4Event?.event_parameters
                pageGTMEvent({
                  ...ga4Event,
                  event_parameters: {
                    ...event_parameters,
                    position: learnMore.fold
                      ? 'bundle_pop_showMore'
                      : 'bundle_pop_showLess',
                  },
                })
                pageGTMEvent({
                  event: 'uaEvent',
                  eventCategory: learnMore.fold
                    ? 'bundle_pop_showMore'
                    : 'bundle_pop_showLess',
                  eventAction: gtmAction || 'learn_more',
                  eventLabel: gtmLabel || '',
                })
              }
              setLearnMore({
                fold: !learnMore.fold,
                height: learnMore.fold
                  ? descDom.current.offsetHeight
                  : boxMinHeight,
              })
            }}
          >
            {learnMore.fold
              ? `${settings?.learn_more || settings?.learnMore}`
              : `${settings?.learn_less || settings.learnLess}`}
            <ArrowDown
              className={cn(s.arrow, { [s.arrowActive]: !learnMore.fold })}
            />
          </button>
        )}
      </div>
    </>
  )
}

export default memo(LearnMore)
