import { Picture } from '@components/ui'
import dynamic from 'next/dynamic'
import { useMemo } from 'react'
import { ProductModelDisplayProps } from './types'

interface DynamicProps extends ProductModelDisplayProps {
  dynamicLoadingRender?: () => JSX.Element
}

const ProductModelDisplay = ({
  dynamicLoadingRender,
  ...props
}: DynamicProps) => {
  const Dynamic = useMemo<any>(
    () =>
      dynamic(() => import('./ProductModelDisplay'), {
        suspense: false,
        ssr: false,
        loading: dynamicLoadingRender
          ? dynamicLoadingRender
          : () => {
              return (
                <div className="absolute top-0 left-0 right-0 bottom-0 bg-white z-10 flex flex-col justify-center items-center">
                  <div className="mb-[80px] w-[140px]">
                    <Picture
                      source="https://cdn.shopify.com/s/files/1/0517/6767/3016/files/loading-animation.png?v=1669818823"
                      alt="loading"
                    />
                  </div>
                </div>
              )
            },
      }),
    []
  )

  return <Dynamic {...props}></Dynamic>
}

export default ProductModelDisplay
