/* eslint-disable @next/next/no-img-element */
import cn from 'classnames'
import React, { JSXElementConstructor } from 'react'
import styles from './ColorSwatch.module.css'
import { BRAND } from '@shopify/const'
interface SwatchProps {
  color: string
  s?: any
  cnStyles?: any
  active?: boolean
  className?: string
  imagePath?: string | null
  quantity?: number
  disabled?: boolean
  border?: boolean
  Component?: string | JSXElementConstructor<any>
}

const ColorSwatch: React.FC<SwatchProps> = React.memo(
  ({
    color = '', // 两种类型: hexColor 直接展示，传 option 名对应匹配 option 名图片
    s: gs,
    cnStyles = {},
    active,
    className,
    Component = 'button',
    imagePath = '', // shopify 的图片路由
    disabled = false,
    border = true,
    quantity = 0, //在颜色上展示产品数量的需求
    ...props
  }) => {
    const s = { ...styles, ...gs }
    const hexColor = color.startsWith('#') ? color : ''

    return (
      <Component
        aria-label={hexColor ? 'Variant Swatch' : color}
        title={hexColor ? '' : color}
        disabled={disabled}
        className={cn(
          s.swatch,
          cnStyles.swatch,
          {
            [s.active]: active,
            [s.disable]: disabled,
            [s.ankermakeSwatch]: BRAND === 'ankermake',
          },
          border && 'border border-[#e2e2e2]',
          className
        )}
        {...props}
      >
        <span
          className={cn(
            'h-full w-full overflow-hidden',
            s.optionColor,
            border && ' border-[0.5px] border-[#ddd]'
          )}
          style={hexColor ? { backgroundColor: hexColor } : {}}
        >
          {Number(quantity) > 0 && (
            <div className={s.quantityCount}>{quantity}</div>
          )}
          {!hexColor && imagePath && (
            <img
              className={cn('h-full w-full', s.colorImage)}
              src={`${getShopifyCdnBaseUrl(imagePath)}${color
                .toLowerCase()
                .split(' ')
                ?.join('-')}_${'50x'}.png`}
              alt={color}
            />
          )}
        </span>
      </Component>
    )
  }
)

ColorSwatch.displayName = 'Swatch'

export default ColorSwatch

const getShopifyCdnBaseUrl = (imagePath: string | null) => {
  // 目前知道的 shopify 图片路由的形式
  // https://cdn.shopify.com/s/files/1/0493/9834/9974/files/Group_5581_2.jpg?v=1662379184
  // https://cdn.shopify.com/s/files/1/0493/9834/9974/products/B2633123.jpg?v=1631264041_3840x
  // 返回 https://cdn.shopify.com/s/files/1/0493/9834/9974/files/
  let path
  if (imagePath?.includes('products')) {
    path = imagePath?.split('products')[0] + 'files/'
  } else {
    path = imagePath?.replace(imagePath?.split('files')?.pop() || '', '') + '/'
  }
  return path
}
