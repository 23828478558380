import cn from 'classnames'
import { memo, useEffect } from 'react'
import { Text } from '@components/ui'
import { ColorSwatch } from '@components/product'
import dynamic from 'next/dynamic'
import { getProductVariant } from 'components/product/helpers'
import styles from './ProductOptions.module.css'
import jump from 'jump.js'
import { pageGTMEvent } from '@lib/utils/thirdparty'

const ProductPackage = dynamic(() => import('../ProductPackage'))

const ProductOptions = ({
  s: gs = {},
  cnStyles = {},
  productImage,
  options,
  selectedOptions,
  setSelectedOptions,
  product,
  floatMode = false,
  swatchStyles,
  quantitys,
  hideDisplayName = false,
  className,
}) => {
  useEffect(() => {
    if (window?.affirm?.ui?.refresh) window.affirm.ui.refresh()
  }, [selectedOptions])

  if (options?.length === 0) return null
  const s = { ...styles, ...gs }
  const productInfos = product.metafields?.productInfos

  const clickOptionHandle = ({ selectedOptions, opt, optVariant, v }) => {
    pageGTMEvent({
      event: 'uaEvent',
      eventCategory: 'option_' + opt?.displayName,
      eventAction: optVariant?.sku, //变量，取该listing的SKU
      eventLabel: v.label,
    })
    pageGTMEvent({
      event: 'ga4Event',
      event_name: 'option_' + opt?.displayName,
      event_parameters: {
        page_group: 'Product Detail Page_' + optVariant?.sku, //取该listing的SKU
        button_name: v.label,
      },
    })
    pageGTMEvent({
      event: 'view_item',
      page_group: 'Product Detail Page_' + optVariant?.sku,
      ecommerce: {
        currency: product?.price?.currencyCode,
        value: optVariant?.variant_price4wscode || optVariant?.price,
        items: [
          {
            item_id: optVariant?.sku,
            item_name: product?.name,
            item_brand: product?.vendor,
            item_category: '',
            item_variant: optVariant?.name,
            price: optVariant?.price,
          },
        ],
      },
    })
    setSelectedOptions(
      {
        ...selectedOptions,
        [opt.displayName.toLowerCase()]: v?.label.toLowerCase(),
      },
      {
        displayName: opt.displayName.toLowerCase(),
        label: v?.label.toLowerCase(),
      },
      optVariant
    )
  }
  return (
    <div
      className={cn(s.optionsBox, s.borderBtm, className, 'productOptionsBox')}
    >
      {options.map((opt, index) => {
        let Option
        if (opt.displayName == 'color') {
          Option = opt.values.map((v, i) => {
            const active = selectedOptions[opt.displayName.toLowerCase()]
            const optVariant = getProductVariant(product, {
              ...selectedOptions,
              [opt.displayName.toLowerCase()]: v.label.toLowerCase(),
            })
            return (
              <ColorSwatch
                key={`${opt.id}-${v.label}-${i}`}
                active={v.label.toLowerCase() === active}
                color={v.hexColors ? v.hexColors[0] : v.label}
                imagePath={productImage}
                s={swatchStyles}
                cnStyles={cnStyles}
                className="p-1"
                quantity={quantitys?.[optVariant.id]?.quantity}
                disabled={!optVariant?.sku}
                onClick={() => {
                  clickOptionHandle({
                    selectedOptions,
                    opt,
                    optVariant,
                    v,
                  })
                }}
              />
            )
          })
        } else if (opt.displayName == 'package') {
          Option = opt.values.map((v, i) => {
            const active = selectedOptions[opt.displayName.toLowerCase()]
            const optVariant = getProductVariant(product, {
              ...selectedOptions,
              [opt.displayName.toLowerCase()]: v.label.toLowerCase(),
            })
            return (
              <ProductPackage
                key={`${opt.id}-${i}`}
                product={product}
                variant={optVariant}
                checked={v.label.toLowerCase() === active}
                label={v.label}
                onPackageClick={() => {
                  setSelectedOptions(
                    {
                      ...selectedOptions,
                      [opt.displayName.toLowerCase()]: v.label.toLowerCase(),
                    },
                    {
                      displayName: opt.displayName.toLowerCase(),
                      label: v.label.toLowerCase(),
                    },
                    optVariant
                  )

                  const itemDom = document.querySelector('.accessories')
                  itemDom &&
                    jump(itemDom, {
                      duration: 1500,
                      offset: -30,
                    })
                }}
                disabled={!optVariant?.sku}
              />
            )
          })
        } else {
          Option = opt.values.map((v, i) => {
            const active = selectedOptions[opt.displayName.toLowerCase()]
            const optVariant = getProductVariant(product, {
              ...selectedOptions,
              [opt.displayName.toLowerCase()]: v.label.toLowerCase(),
            })
            return (
              <button
                className={cn(
                  s.optionItem,
                  cnStyles?.optionItem,
                  styles?.optionItem,
                  {
                    [s.optionItemActive]: v.label.toLowerCase() === active,
                    [cnStyles?.optionItemActive]:
                      v.label.toLowerCase() === active,
                  },
                  floatMode && '!w-full',
                  {
                    'opacity-50': !optVariant?.sku,
                    ['basis-[96%] md:basis-[100%]']:
                      productInfos?.optionsFullWidth,
                    ['!justify-start']:
                      productInfos?.optionsTextAlign === 'left',
                  }
                )}
                key={`${opt.id}-${i}`}
                onClick={() => {
                  clickOptionHandle({
                    selectedOptions,
                    opt,
                    optVariant,
                    v,
                  })
                }}
                disabled={!optVariant?.sku}
              >
                <Text className={s.itemLabel} html={v?.label} />
              </button>
            )
          })
        }
        return (
          <div
            className={cn(s.optionsBox, cnStyles?.optionsBox)}
            key={opt?.displayName + index}
          >
            {!hideDisplayName && (
              <h2 className={cn(s.displayName, cnStyles.displayName)}>
                {opt.name}
              </h2>
            )}
            <fieldset
              className={cn(
                s.listbox,
                cnStyles?.listbox,
                s[opt.displayName === 'color' ? 'optionColor' : 'optionLabel'],
                styles?.listbox
              )}
            >
              {Option}
            </fieldset>
          </div>
        )
      })}
    </div>
  )
}

export default memo(ProductOptions)
